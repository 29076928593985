import CustomLayout from "./wrapPageElement"
import "./src/styles/global.css"

export const wrapPageElement = CustomLayout

const site__wrapper = document.getElementById("___gatsby")
export const onClientEntry = () => {
  site__wrapper && site__wrapper.classList.add("js")
}
export const onInitialClientRender = () => {
  site__wrapper && document.body.classList.add("entry")
}

export const onRouteUpdate = () => {
  site__wrapper && site__wrapper.classList.remove("preloader_ready");
  site__wrapper && site__wrapper.classList.add("preloader_active")
  setTimeout(function () {
    site__wrapper && site__wrapper.classList.remove("preloader_active");
    site__wrapper && site__wrapper.classList.add("preloader_ready");
  }, 2000);
}