import React, { Fragment } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import LocalizedLink from "./localizedLink";
import useTranslations from "../hooks/useTranslations";
import { LocaleContext } from "./layout";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  SupportIcon,
  GlobeAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import SvgLogo from "./svgLogo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const { locale } = React.useContext(LocaleContext);
  const data = useStaticQuery(graphql`
    {
      BoatMenu: allGoogleMenuSheet(
        filter: { type: { eq: "boat" } }
        sort: { fields: menuNameDe }
      ) {
        nodes {
          menuNameDe
          menuNameEn
          menuBeschreibungDe
          menuBeschreibungEn
          anchor
          link
        }
      }
      MoreMenu: allGoogleMenuSheet(
        filter: { type: { eq: "more" } }
        sort: { fields: menuNameDe }
      ) {
        nodes {
          menuNameDe
          menuNameEn
          menuBeschreibungDe
          menuBeschreibungEn
          anchor
          link
        }
      }
    }
  `);
  const { menu } = useTranslations();

  const boatTypes = [
    {
      name: locale === "de" ? "Alle Boote" : "All boats",
      href: "/boote",
    },
  ];
  const callsToAction = [
    {
      name: menu.bookingOverview,
      href: "/online-buchen",
      icon: CursorClickIcon,
    },
    {
      name: menu.callSupport,
      href: "tel:+491723250009",
      icon: PhoneIcon,
      extern: true,
    },
  ];
  const resources = [
    {
      name:
        locale === "de"
          ? data.MoreMenu.nodes[0].menuNameDe
          : data.MoreMenu.nodes[0].menuNameEn,
      description:
        locale === "de"
          ? data.MoreMenu.nodes[0].menuBeschreibungDe
          : data.MoreMenu.nodes[0].menuBeschreibungEn,
      href: "/faq",
      icon: SupportIcon,
    },
    {
      name:
        locale === "de"
          ? data.MoreMenu.nodes[1].menuNameDe
          : data.MoreMenu.nodes[1].menuNameEn,
      description:
        locale === "de"
          ? data.MoreMenu.nodes[1].menuBeschreibungDe
          : data.MoreMenu.nodes[1].menuBeschreibungEn,
      href: "/schleusen-ratgeber",
      icon: BookmarkAltIcon,
    },
  ];

  return (
    <>
      <Popover className="sticky top-0 z-20 sm:relative blur-fallback backdrop-blur bg-white/70">
        <div className="px-4 mx-auto max-w-7xl sm:px-6">
          <div className="flex items-center justify-between py-6 border-b-2 border-black/5 lg:border-0 lg:justify-start lg:space-x-10">
            <div className="flex justify-start">
              <LocalizedLink
                className="flex flex-row-reverse items-end gap-4"
                to="/"
              >
                <div className="font-bold leading-none tracking-wider uppercase transform skew-x-3 lg:hidden">
                  <span className="sr-only">ALS-</span>
                  <span className="text-als-red">Boots</span>verleih
                  <span className="sr-only">Berlin Köpenick</span>
                </div>
                <SvgLogo className="h-6 sm:h-8" />
              </LocalizedLink>
            </div>
            <div className="-my-2 -mr-2 lg:hidden">
              <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-als-red">
                <span className="sr-only">Open menu</span>
                <span className="mr-2 text-sm not-sr-only">Menu</span>
                <MenuIcon className="w-6 h-6" aria-hidden="true" />
              </Popover.Button>
            </div>

            {/* DESKTOP MENU */}
            <Popover.Group
              as="nav"
              className="hidden lg:grid lg:grid-cols-4 nav--skewed justify-items-center animate-children from-top"
            >
              <Popover className="nav__item nav__item--1">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? '' : '',
                        "group capitalize inline-flex items-center text-base font-medium focus:outline-none"
                      )}
                    >
                      <span>{menu.boats}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                            {boatTypes.map((item) => (
                              <Popover.Button
                                as={LocalizedLink}
                                key={item.name}
                                to={item.href}
                                className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                              >
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Popover.Button>
                            ))}
                            {data.BoatMenu.nodes.map((item) => {
                              const name =
                                locale === "de"
                                  ? item.menuNameDe
                                  : item.menuNameEn;
                              const description =
                                locale === "de"
                                  ? item.menuBeschreibungDe
                                  : item.menuBeschreibungEn;
                              const to = !!item.anchor
                                ? `/boote?${item.anchor}`
                                : `/${item.link}`;
                              return (
                                <Popover.Button
                                  as={LocalizedLink}
                                  key={name}
                                  to={to}
                                  state={{ filterz: item.anchor }}
                                  className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                                >
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      {name}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {description}
                                    </p>
                                  </div>
                                </Popover.Button>
                              );
                            })}
                          </div>
                          <div className="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                {item.extern ? (
                                  <a
                                    href={item.href}
                                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                                  >
                                    <item.icon
                                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-3">{item.name}</span>
                                  </a>
                                ) : (
                                  <Popover.Button
                                    as={LocalizedLink}
                                    key={item.name}
                                    to={item.href}
                                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                                  >
                                    <item.icon
                                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-3">{item.name}</span>
                                  </Popover.Button>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <div className="nav__item nav__item--2">
                <LocalizedLink
                  to={`/touren`}
                  className="inline-flex items-center text-base font-medium capitalize outline-none"
                  activeClassName="active"
                >
                  {menu.tours}
                </LocalizedLink>
              </div>
              <div className="nav__item nav__item--3">
                <LocalizedLink
                  to={`/gutscheine`}
                  className="inline-flex items-center text-base font-medium capitalize outline-none"
                  activeClassName="active"
                >
                  {menu.vouchers}
                </LocalizedLink>
              </div>

              <Popover className="nav__item nav__item--4">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? 'text-white' : 'text-gray-500',
                        "group inline-flex items-center text-base font-medium focus:outline-none"
                      )}
                    >
                      <span>{menu.more}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                            {resources.map((item) => (
                              <Popover.Button
                                as={LocalizedLink}
                                key={item.name}
                                to={item.href}
                                className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                              >
                                <item.icon
                                  className="flex-shrink-0 w-6 h-6 text-als-red"
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Popover.Button>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <div className="hidden col-span-4 col-start-1 row-start-1 lg:grid animated-bar">
                <div className="animated-bar__inner"></div>
              </div>
            </Popover.Group>

            <div className="items-center justify-end hidden gap-8 lg:flex lg:flex-1 lg:w-0 animate-children from-top">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex items-center max-w-xs text-sm text-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white hover:text-gray-800">
                    <span className="sr-only">Open language menu</span>
                    <GlobeAltIcon className="w-6 h-6 rounded-full" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <Link
                        to="/"
                        hrefLang="de"
                        className="block px-4 py-2 text-sm text-gray-700"
                        activeClassName="bg-gray-100"
                      >
                        {menu.german}
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/en"
                        hrefLang="en"
                        className="block px-4 py-2 text-sm text-gray-700"
                        activeClassName="bg-gray-100"
                      >
                        {menu.english}
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <LocalizedLink
                to="/kontakt"
                className="text-base font-medium text-gray-500 capitalize whitespace-nowrap hover:text-gray-900"
              >
                {menu.contact}
              </LocalizedLink>
              <LocalizedLink
                to="/online-buchen"
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white capitalize border border-transparent btn btn--skewed whitespace-nowrap"
              >
                {menu.bookNow}
              </LocalizedLink>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 max-h-screen p-2 overflow-auto transition origin-top-right transform lg:hidden"
          >
            <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <SvgLogo />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-als-red">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {boatTypes.map((item) => (
                      <Popover.Button
                        as={LocalizedLink}
                        key={item.name}
                        to={item.href}
                        className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                      >
                        {/* <item.icon className="flex-shrink-0 w-6 h-6 text-als-red" aria-hidden="true" /> */}
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </Popover.Button>
                    ))}
                    {data.BoatMenu.nodes.map((item) => {
                      const name =
                        locale === "de" ? item.menuNameDe : item.menuNameEn;
                      const to = !!item.anchor
                        ? `/boote?${item.anchor}`
                        : `/${item.link}`;
                      return (
                        <Popover.Button
                          as={LocalizedLink}
                          key={name}
                          to={to}
                          className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                        >
                          <span className="ml-3 text-base font-medium text-gray-900">
                            {name}
                          </span>
                        </Popover.Button>
                      );
                    })}
                  </nav>
                </div>
              </div>
              <div className="px-5 py-6 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <Popover.Button
                    as={LocalizedLink}
                    to="/touren"
                    className="text-base font-medium text-gray-900 capitalize hover:text-gray-700"
                  >
                    {menu.tours}
                  </Popover.Button>
                  <Popover.Button
                    as={LocalizedLink}
                    to="/gutscheine"
                    className="text-base font-medium text-gray-900 capitalize hover:text-gray-700"
                  >
                    {menu.vouchers}
                  </Popover.Button>
                  {resources.map((item) => (
                    <Popover.Button
                      as={LocalizedLink}
                      key={item.name}
                      to={item.href}
                      className="text-base font-medium text-gray-900 capitalize hover:text-gray-700"
                    >
                      {item.name}
                    </Popover.Button>
                  ))}
                  <Popover.Button
                    as={LocalizedLink}
                    to="/kontakt"
                    className="text-base font-medium text-gray-900 capitalize hover:text-gray-700"
                  >
                    {menu.contact}
                  </Popover.Button>
                </div>
                <div>
                  <Popover.Button
                    as={LocalizedLink}
                    to="/online-buchen"
                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-als-red over-hue-rotation"
                  >
                    {menu.bookNow}
                  </Popover.Button>
                </div>
                <div className="flex justify-center place-items-center">
                  <GlobeAltIcon className="w-6 h-6 rounded-full" />
                  <Link
                    to="/"
                    hrefLang="de"
                    className="block px-4 py-2 text-sm text-gray-700"
                    activeClassName="bg-gray-100"
                  >
                    {menu.german}
                  </Link>
                  <Link
                    to="/en"
                    hrefLang="en"
                    className="block px-4 py-2 text-sm text-gray-700"
                    activeClassName="bg-gray-100"
                  >
                    {menu.english}
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
