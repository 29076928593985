exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-boote-google-boote-sheet-slug-de-js": () => import("./../../../src/pages/boote/{GoogleBooteSheet.slugDe}.js" /* webpackChunkName: "component---src-pages-boote-google-boote-sheet-slug-de-js" */),
  "component---src-pages-boote-index-js": () => import("./../../../src/pages/boote/index.js" /* webpackChunkName: "component---src-pages-boote-index-js" */),
  "component---src-pages-danke-js": () => import("./../../../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-floesse-und-party-boote-js": () => import("./../../../src/pages/floesse-und-party-boote.js" /* webpackChunkName: "component---src-pages-floesse-und-party-boote-js" */),
  "component---src-pages-gutscheine-js": () => import("./../../../src/pages/gutscheine.js" /* webpackChunkName: "component---src-pages-gutscheine-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-online-buchen-js": () => import("./../../../src/pages/online-buchen.js" /* webpackChunkName: "component---src-pages-online-buchen-js" */),
  "component---src-pages-schleusen-ratgeber-js": () => import("./../../../src/pages/schleusen-ratgeber.js" /* webpackChunkName: "component---src-pages-schleusen-ratgeber-js" */),
  "component---src-pages-touren-google-touren-sheet-slug-de-js": () => import("./../../../src/pages/touren/{GoogleTourenSheet.slugDe}.js" /* webpackChunkName: "component---src-pages-touren-google-touren-sheet-slug-de-js" */),
  "component---src-pages-touren-index-js": () => import("./../../../src/pages/touren/index.js" /* webpackChunkName: "component---src-pages-touren-index-js" */)
}

