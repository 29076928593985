import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "../components/layout";

const useTranslations = () => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext);
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map((item) => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    };
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter((lang) => lang.name === locale)[0];

  return translations;
};

export default useTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            menu {
              boats
              licenseFree
              tours
              vouchers
              more
              contact
              bookNow
              bookingOverview
              callSupport
              german
              english
            }
            footer {
              bookOnline
              directions
              address
              street
              zipCode
              city
              publicTransport
              tram
              tramStop
              walkingDistance
              openWithMaps
              openingHours
              contact
              faqs
              gtcs
              imprint
              privacyPolicy
              reservedRights
              softwareBy
            }
            homePage {
              alsBoatRental
              boats {
                title
                text
                licenseFree
                licenseFreeText
                license
                licenseText
                raft
                raftText
              }
              promos {
                promo1
                promo1text
                promo2
                promo2text
                promo3
                promo3text
              }
              activities {
                title
                text
                topic1
                topic1Text
                topic2
                topic2Text
                topic3
                topic3Text
              }
              tours {
                title
                text
              }
            }
            boatsPage {
              headline
              text
              licenseFree
              licenseRequired
              licenseWithout
              boatType
              license
              persons
              motorboat
              raftAndPartyBoats
              yachtCharter
              houseboat
              jetSki
              sups
              from
              ohCrap
              resetFilter
              notMatch
            }
            boatPage {
              metaTitle
              boats
              from
              upTo
              persons
              hp
              overnight
              licenseRequired
              licenseWithout
              equipmentFeatures
              optionalExtras
              optionalExtrasExplaination
              skipper
              coolerBox
              dryWc
              dryWcInclBags
              waterproofsBarrel
              dryBag
              booking
              our
              personalContact
              callUs
              multiDayBooking
              keyData {
                title
                length
                width
                draught
                weight
                fuelTank
              }
              prices {
                title
                perHour
                perDay
                flatRate
                pricePerHour
                pricePerHourDuration
                standard
                standardDuration
                fullDay
                fullDayDuration
                afterWork
                afterWorkDuration
                partyToMidnight
                partyToMidnightDuration
                hours3
                days3
                days4
                days5
                days6
                days7
                discount
                discountDuration
                discount2
                discount2Duration
                discount3
                discount3Duration
                discountBeer
                discountBeerDuration
                deposit
                depositDuration
                fuel
                fuelDuration
                fullTankOnReturn
                fullTankOnReturnDescription
              }
            }
            bookingPage {
              headline
              text
              arrowText
            }
            toursPage {
              discover
              berlinAndBrandenburg
              shortDayTrips
              shortDayTripsText
              shortDayTripsWithRaft
              shortDayTripsWithRaftText
              showAll
            }
            tourPage {
              keyData
              journeyTime
              locks
              noLocks
            }
            vouchersPage {
              giveAwayExperiences
              text
              receiveVoucher
              redeemVoucher
              multipleVouchers
              limitation
            }
            faqPage {
              title
              reservation
              payment
              badWeather
              noAnswers
              write
              call
            }
            thankYouPage {
              title
              text
            }
            contactPage {
              title
              text
            }
            contactForm {
              honey
              title
              tel
              message
              messagePlaceholder
              privacy1
              privacy2
              privacy3
              send
            }
            gtcPage {
              title
            }
            loadingAnimation {
              text1
            }
            abbreviation {
              approx
              hp
            }
            actions {
              viewAll
            }
            separator
            discoverNow
            backToHome
          }
        }
      }
    }
  }
`;
