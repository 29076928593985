import * as React from "react"

function SvgLogo(props) {
    return (
        <svg
        className="w-24 h-auto text-gray-800 transition-colors duration-300 hover:text-als-red focus:ring-2 focus:ring-inset focus:ring-als-red sm:w-28"
        viewBox="0 0 213 79"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <mask id="a" fill="#fff">
            <path d="M.5 79L21 40h20L31 62h31L37.5 0h20l28 79H.5zM88.5 79L114 0h21l-21 62h71c5 0 7.5-2.9 7.5-8.5 0-7-13.5-10.5-23-13.5s-19-10-19-22c0-9.6 11-16 16-18H197c-17.5 1.5-31 10.5-31 17s9 10 20 13.5S212.5 42 212.5 55 199 79 185 79H88.5z" />
        </mask>
        <path
            d="M.5 79L21 40h20L31 62h31L37.5 0h20l28 79H.5zM88.5 79L114 0h21l-21 62h71c5 0 7.5-2.9 7.5-8.5 0-7-13.5-10.5-23-13.5s-19-10-19-22c0-9.6 11-16 16-18H197c-17.5 1.5-31 10.5-31 17s9 10 20 13.5S212.5 42 212.5 55 199 79 185 79H88.5z"
            stroke="currentColor"
            strokeWidth={32}
            mask="url(#a)"
        />
        </svg>
    )
}

  export default SvgLogo
  