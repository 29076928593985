import React from "react";
import { Helmet } from "react-helmet"
import Header from "./header";
import Footer from "./footer";


const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ( { children, mainClasses, pageContext: { locale } }) => {
    return (
    <LocaleContext.Provider value={{ locale }}>
        <Helmet>
            <link rel="icon" href="/favicon.ico" sizes="any" />
            <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
            <script>
                {`
                window.RENTWARE_BASE_API_URL = 'https://als-bootsverleih.rentware.io';
                window.RENTWARE_LANGUAGE = '${ locale === 'de' ? 'de-DE' : 'en-GB'}';
                `}
            </script>
            <script type="module" src="https://w-cdn.rentware.io/dist/rentware-widgets.esm.js"></script>
            <title>{locale === 'de' ? 'ALS Bootsverleih® / Jetzt Boote in Berlin mieten' : 'ALS Bootsverleih® '}</title>
            <meta name="description" content="TOP-bewertet! Mietet euch ein (Motor-)Boot & beschert euch eine schöne Zeit auf den Gewässern Berlins. ➜ Einfach online buchen!" />
        </Helmet>
        <Header/>
        <main className={mainClasses}>{children}</main>
        <Footer />
        <rentware-checkout></rentware-checkout>
    </LocaleContext.Provider>
)}

export { Layout, LocaleContext }